import api from "./api";

class UtilsService {
  /* innovative */
  AddComment(data) {
    return api.post(`/comments`, data);
  }
  getStates() {
    return api.get("/states");
  }
  getComments(client_id) {
    return api.get(`/comments?client_id=${client_id}`);
  }
  getAgencies() {
    return api.get("/transponder-agencies");
  }
  getOrders() {
    return api.get("/orders");
  }
  updateOrder(id, data) {
    return api.post(`/orders/${id}?_method=put`, data);
  }
  addOrder(data) {
    return api.post("/orders", data);
  }
  getClientAgencies(filter) {
    return api.get("/client-agency", { params: filter });
  }
  getClients(filter) {
    return api.get("/clients", { params: filter });
  }
  getCarriers(filter) {
    return api.get("/clients", { params: filter });
  }
  getClientsLogs() {
    return api.get("/client-logs");
  }
  getClientsInsights() {
    return api.get("/clients-insights");
  }
  getopenroadsInsights() {
    return api.get("/openroads-clients-insights");
  }
  getAllProjects(filter) {
    return api.get("/projects", { params: filter });
  }
  getClientsAdds() {
    return api.get("/vehicles?draft=1&client_id");
  }
  getClientDefaultUser(clientId) {
    return api.get(`/default-user/${clientId}`);
  }
  getClientsUploads() {
    return api.get("/client-uploads");
  }
  getuserdetails(userId) {
    return api.get(`/contact-user/${userId}`);
  }
  deleteClientsUploads(vehicle_id) {
    return api.delete(`/client-uploads/${vehicle_id}`);
  }
  getLpTypes() {
    return api.get("/report-types");
  }
  getInventoryStatus() {
    return api.get("/inventory-status");
  }
  getTransponderStatus() {
    return api.get("/transponder-status");
  }

  getColor() {
    return api.get(`/transponder-colors`);
  }
  getTypes() {
    return api.get(`/transpondertypes`);
  }
  getDepartments(client) {
    return api.get(`/departments?client_id=${client}`);
  }
  getSubDepartments({ dept }) {
    return api.get("/sub-departments", null, { dept });
  }
  getSubsidiaries({ client }) {
    return api.get("/subsidiaries", null, { client });
  }
  getAuthUser() {
    return api.get(`/current_employee`);
  }
  async getOverviewDept(client) {
    return await api.get(`/default-department/${client}`);
  }
  getclientdetails(clientId) {
    return api.get(`/clients/${clientId}`);
  }
  getDept(deptId) {
    return api.get(`/departments/${deptId}`);
  }

  //transactions
  AddExcelTransaction(data) {
    return api.post("/excel-dumps", data);
  }
  getExcelDetails() {
    return api.get(`/excel-dumps`);
  }
  getAccounts() {
    return api.get(`/accounts-balances`);
  }

  //logs
  GetLogs(user_id, draw, length, start, order, pagedir) {
    return api.get(
      `/logs?user_id=${user_id}&draw=${draw}&length=${length}&start=${start}&order=${order}&pagedir=${pagedir}`
    );
  }
  uniqdashboard(cl_level, client_id) {
    return api.get(`/dashboard?cl_id=${cl_level}&client_id=${client_id}`);
  }
  getFileValidationProgress(user_id) {
    return api.get(`/validation-status/${user_id}`);
  }
  Totals() {
    return api.get(`/dashboard-per-client`);
  }

  //logs
  GetLogs(user_id, draw, length, start, order, pagedir) {
    return api.get(
      `/logs?user_id=${user_id}&draw=${draw}&length=${length}&start=${start}&order=${order}&pagedir=${pagedir}`
    );
  }
  Getrules(user_id, draw, length, start, order, pagedir) {
    return api.get(
      `/business-rules?user_id=${user_id}&draw=${draw}&length=${length}&start=${start}&order=${order}&pagedir=${pagedir}`
    );
  }
  GetClientRules(client_id, user_id, draw) {
    return api.get(
      `/business-rules/${client_id}?user_id=${user_id}&draw=${draw}`
    );
  }
  addRules(user_id, data) {
    return api.post(`/business-rules?user_id=${user_id}`, data);
  }
  UpdateRules(client_id, user_id, dataE) {
    return api.post(
      `/business-rules/${client_id}?user_id=${user_id}&_method=put`,
      dataE
    );
  }
  MarkUnique(
    user_id,
    cl_id,
    transaction_id,
    validation_assignment,
    first_occurrence,
    notes
  ) {
    return api.get(
      `/move-to-unique?user_id=${user_id}&cl_id=${cl_id}&transaction_id=${transaction_id}&validation_assignment=${validation_assignment}&first_occurrence=${first_occurrence}&notes=${notes}`
    );
  }
  MarkDuplicate(user_id, cl_id, transaction_id, notes) {
    return api.get(
      `/move-to-duplicate?user_id=${user_id}&cl_id=${cl_id}&transaction_id=${transaction_id}&notes=${notes}`
    );
  }
  // GetClientrules
  getClientsUnique() {
    return api.get("/unique-clients");
  }

  getFileNumber(draw) {
    return api.get(`/file-tracking?draw=${draw}`);
  }
  addClients(user_id, data) {
    return api.post(`/unique-clients?user_id=${user_id}`, data);
  }
  updateClients(client_id, user_id, dataE) {
    return api.post(
      `/unique-clients/${client_id}?user_id=${user_id}&_method=put`,
      dataE
    );
  }
  //HCTRA
  uniqdashboard(cl_level, client_id) {
    return api.get(`/dashboard?cl_id=${cl_level}&client_id=${client_id}`);
  }
  hctraVehicles() {
    return api.get(`/hctra_vehicles`);
  }
  hctraVehiclesStatus(status) {
    return api.get(`/hctra_vehicles?status=${status}`);
  }
  PosthctraVehicles(data) {
    return api.post(`/hctra_vehicles`, data);
  }
  getAuthUser() {
    return api.get(`/current_employee`);
  }
  TransactionsHCTRA() {
    return api.get(`/hctra_transactions`);
  }
  AccountsHCTRA() {
    return api.get(`/hctra_balance`);
  }
  DeactivateHCTRAVehicle(account, lp, state, data) {
    return api.get(`/hctra_vehicles/${account}/${lp}/${state}`, data);
  }

  MarkAsInactive(vehicleID, data) {
    return api.patch(`/mark-deactivated/${vehicleID}`, data);
  }
  MarkCarrierAsInactive(vehicleID, data) {
    return api.patch(`carrier-mark-deactivated/${vehicleID}?vehicle_status_id=${data}`);
  }
  GetAllInactive() {
    return api.get(`/inactivetransponders`);
  }
  GetCarrierInactive() {
    return api.get(`/carrierinactivetransponders`);
  }
  GetInactive() {
    return api.get(`/inactive-transponders`);
  }
  GetDeactive() {
    return api.get(`/deactivate-transponder`);
  }
  GetSubsidiary() {
    return api.get(`/business-units`);
  }
  GetFailedReplenish() {
    return api.get(`/failed-replenish`);
  }
  getClientAccounts(dept_id) {
    return api.get(`/accounts-balances?dept_id=${dept_id}`);
  }
  getClientLogs(client_id) {
    return api.get(`/client-logs?client_id=${client_id}`);
  }
  getRegions() {
    return api.get(`/travel-regions`);
  }
    sendOtp(data) {
    return api.post('/send_email_otp', data)
  } 
    resetPassword(data) {
    return api.post('/updatepassword', data)
  } 
}

export default new UtilsService();
